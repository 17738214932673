const formatDateTime = (value) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(value);
  return `${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()} ${
    date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
  }:${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}:${
    date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()
  }`;
};
const formatDateTimeComponent = (value) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(value);

  return (
    <div className="flex flex-col w-full justify-center">
      <span>
        {`${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()} ${
          monthNames[date.getMonth()]
        } ${date.getFullYear()} `}
      </span>
      <span className="mt-1">
        {`${date.getHours() > 9 ? date.getHours() : "0" + date.getHours()}:${
          date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
        }:${
          date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()
        }`}
      </span>
    </div>
  );
};

const formatDate = (value) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(value);
  return `${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
};

export const storeData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // saving error
  }
};

export const getData = (key) => {
  try {
    const json = localStorage.getItem(key);
    return json != null ? JSON.parse(json) : null;
  } catch (error) {
    // error reading value
  }
};

export { formatDate, formatDateTime, formatDateTimeComponent };
