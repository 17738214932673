import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";
import { getData } from "../utils";

export async function getKeys() {
  const user = await getData("user");
  return await axios.get(`${EMI_BOOK}/keys/${user.id}`, {
    headers: getHeaders(true),
  });
}

export async function getUserKeysDetails() {
  const user = await getData("user");
  return await axios.get(`${EMI_BOOK}/keys/${user.id}`, {
    headers: getHeaders(true),
  });
}

export async function getAllKeys(payload) {
  return await axios.get(`${EMI_BOOK}/keys`, {
    params: {
      page: payload.page,
      size: payload.size,
    },
    headers: getHeaders(true),
  });
}
