import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";

export const createPromotions = async (payload) => {
  return axios.post(`${EMI_BOOK}/promotion`, payload, {
    headers: getHeaders(true),
  });
};

export async function deletePromotion(id) {
  return await axios.delete(`${EMI_BOOK}/promotion/` + id, {
    headers: getHeaders(true),
  });
}

export const getPromotions = async (payload) => {
  return axios.get(`${EMI_BOOK}/promotion/filter`, {
    params: {
      isActive: true,
      size: payload.size,
      type: "IMAGE",
      page: payload.page,
    },
    headers: getHeaders(true),
  });
};
