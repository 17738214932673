import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { customerRegistrations } from "apis/customerRegistration";
import Pagination from "@mui/material/Pagination";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";

import Stack from "@mui/material/Stack";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { formatDate } from "utils";
import Grid from "@mui/material/Unstable_Grid2";

export default function CustomerReg() {
  const color = "light";
  const [reg, setReg] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState({
    page: 0,
    mobile: "",
    imei1: "",
    imei2: "",
    size: 25,
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const getRegistrations = async (filters) => {
    try {
      const { data } = await customerRegistrations(filters);

      setReg(data.data);
      setTotalPages(data.pages);
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const obj = {
        ...payload,
        mobile: payload.mobile.length === 10 ? payload.mobile : null,
        imei1: payload.imei1.length === 15 ? payload.imei1 : null,
        imei2: payload.imei2.length === 15 ? payload.imei2 : null,
      };
      await getRegistrations(obj);
    })();
  }, [payload]);

  const handleEmiDetailsClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const handlePageChange = (event, value) => {
    setPayload((prev) => ({
      ...prev,
      page: value - 1, // Pagination component is 1-based, API is 0-based
    }));
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "350px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search by Mobile Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label="Search by Mobile Number"
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        mobile: e.target.value,
                      });
                    }}
                    value={"" + payload.mobile}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "350px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search by IMEI 1
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label="Search by Mobile Number"
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        imei1: e.target.value,
                      });
                    }}
                    value={"" + payload.imei1}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "350px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search by IMEI 2
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label="Search by Mobile Number"
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        imei2: e.target.value,
                      });
                    }}
                    value={"" + payload.imei2}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="flex justify-center my-4">
          <Stack spacing={2}>
            <Pagination
              className={"text-white"}
              count={totalPages}
              page={payload.page + 1}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </div>{" "}
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S no." />
                <TableHeader heading="Name" />
                <TableHeader heading="Status" />
                <TableHeader heading="Version" />
                <TableHeader heading="Locked" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="IMEIS" />
                <TableHeader heading="Retailer" />
                <TableHeader heading="Created On" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {reg.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />

                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.customerDetails?.name}
                        </span>
                      }
                      type="element"
                    />
                    <TableElement value={item?.deviceData?.status} />
                    <TableElement value={item?.version} />
                    <TableElement
                      value={
                        item?.deviceData?.locked ? (
                          <p className="text-red-500">YES</p>
                        ) : (
                          <p className="text-green-500">NO</p>
                        )
                      }
                    />
                    <TableElement value={item?.customerDetails?.mobile} />
                    <TableElement
                      value={
                        <span>
                          {item?.customerDetails?.imei1} <br />
                          {item?.customerDetails?.imei2}
                        </span>
                      }
                    />
                    <TableElement
                      value={
                        <span>
                          {item?.user?.name} <br />
                          {item?.user?.mobile}
                        </span>
                      }
                    />

                    <TableElement value={item?.createdOn} />
                    <TableElement
                      value={
                        <Button onClick={() => handleEmiDetailsClick(item)}>
                          View More
                        </Button>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        customer={selectedCustomer}
        onClose={() => setIsModalOpen(false)}
      /> */}

      <RegistrationDetails
        isOpen={isModalOpen}
        customer={selectedCustomer}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

const Modal = ({ isOpen, onClose, customer }) => {
  if (!isOpen) return null;

  const renderButton = () => {
    const lockStatus = customer?.deviceData?.status;

    switch (lockStatus) {
      case "0":
        return (
          <button
            className="bg-blue-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
            type="button">
            InActive
          </button>
        );
      case "1":
        return (
          <button
            className="bg-green-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
            type="button">
            Active
          </button>
        );
      case "3":
        return (
          <button
            className="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
            type="button">
            Removed
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}></div>
      <div className="bg-white p-3 rounded relative shadow-lg z-50 max-h-[80vh] overflow-y-auto">
        <button
          className="absolute top-0 right-0 py-1 px-3 z-60 bg-red-500 rounded-full text-white"
          onClick={onClose}>
          X
        </button>
        <div>
          <h1 className="font-extrabold text-2xl text-red-600 mb-4 text-center">
            Registration
          </h1>
          <h4 className="text-lg mb-1">
            Name :- {customer?.customerDetails?.name}{" "}
          </h4>
          <h4 className="text-lg mb-1">
            Adhaar :- {customer?.kycDetails?.aadhaar}{" "}
          </h4>
          <h4 className="text-lg mb-1">Pan :- {customer?.kycDetails?.pan} </h4>
          <h4 className="text-lg mb-1">
            Address :- {customer?.kycDetails?.address1},{" "}
            {customer?.kycDetails?.address2}, {customer?.kycDetails?.landmark},{" "}
            {customer?.kycDetails?.district}, {customer?.kycDetails?.state},{" "}
            {customer?.kycDetails?.pinCode}{" "}
          </h4>
          <div className="flex justify-between items-end">
            <div>
              Signature :-
              <img
                src={customer?.kycDetails?.signatureUrl}
                alt="sign"
                loading="lazy"
                className="h-20 bg-white border-4"
              />
            </div>
            <div>
              {customer?.absoluteData?.status === "1" &&
                (customer?.absoluteData?.device?.length > 0 &&
                customer?.absoluteData?.device[0].lock_status &&
                customer?.absoluteData?.device[0].lock_status === "0" ? (
                  <button
                    className="bg-green-500 mx-5 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
                    type="button">
                    UnLocked
                  </button>
                ) : (
                  <button
                    className="bg-red-500 mx-5 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow"
                    type="button">
                    Locked
                  </button>
                ))}
              {<div>{customer?.deviceData?.status}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RegistrationDetails = ({ isOpen, onClose, customer }) => {
  return (
    <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Registration Details</DialogTitle>
      <DialogContent dividers>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-200 ">
              <TableHeader heading="Name" />
              <TableHeader heading="Email" />
              <TableHeader heading="Mobile" />
              <TableHeader heading="Alternate Mobile" />
              <TableHeader heading="IMEI 1" />
              <TableHeader heading="IMEI 2" />
              <TableHeader heading="Serial Number" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.customerDetails?.name} />
              <TableElement value={customer?.customerDetails?.email} />
              <TableElement value={customer?.customerDetails?.mobile} />
              <TableElement
                value={customer?.customerDetails?.alternateMobile}
              />
              <TableElement value={customer?.customerDetails?.imei1} />
              <TableElement value={customer?.customerDetails?.imei2} />
              <TableElement value={customer?.customerDetails?.serialNumber} />
            </tr>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-200 ">
              <TableHeader heading="Device ID" />
              <TableHeader heading="Brand" />
              <TableHeader heading="Model" />
              <TableHeader heading="Status" />
              <TableHeader heading="locked" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.deviceData?.deviceId} />
              <TableElement value={customer?.deviceData?.brand} />
              <TableElement value={customer?.deviceData?.model} />
              <TableElement value={customer?.deviceData?.status} />
              <TableElement
                value={
                  customer?.deviceData?.locked ? (
                    <p className="text-red-500">YES</p>
                  ) : (
                    <p className="text-green-500">NO</p>
                  )
                }
              />
            </tr>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-200 ">
              <TableHeader heading="Aadhaar" />
              <TableHeader heading="PAN" />
              <TableHeader heading="Address" />
              <TableHeader heading="district" />
              <TableHeader heading="State" />
              <TableHeader heading="PIN" />
              <TableHeader heading="alternate Address" />
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.kycDetails?.aadhaar} />
              <TableElement value={customer?.kycDetails?.pan} />
              <TableElement
                value={
                  customer?.kycDetails?.address1
                    ? customer?.kycDetails?.address1 + ", "
                    : "" + customer?.kycDetails?.address2
                    ? customer?.kycDetails?.address2 + ", "
                    : "" + customer?.kycDetails?.landmark
                    ? customer?.kycDetails?.landmark + ", "
                    : ""
                }
              />
              <TableElement value={customer?.kycDetails?.district} />
              <TableElement value={customer?.kycDetails?.state} />
              <TableElement value={customer?.kycDetails?.pinCode} />

              <TableElement value={customer?.kycDetails?.alternateAddress} />
            </tr>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-200 ">
              <TableHeader heading="Customer" />
              <TableHeader heading="Aadhaar Front" />
              <TableHeader heading="Aadhaar Back" />
              <TableHeader heading="Pan" />
              <TableHeader heading="Signature" />
              <TableHeader heading="Invoice" />
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement
                value={
                  customer?.kycDetails?.customerUrl ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.kycDetails?.customerUrl}
                      target="_blank">
                      <img
                        src={customer?.kycDetails?.customerUrl}
                        alt="sign"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.kycDetails?.aadhaarUrl ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.kycDetails?.aadhaarUrl}
                      target="_blank">
                      <img
                        src={customer?.kycDetails?.aadhaarUrl}
                        alt="sign"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.kycDetails?.aadhaarBackUrl ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.kycDetails?.aadhaarBackUrl}
                      target="_blank">
                      <img
                        src={customer?.kycDetails?.aadhaarBackUrl}
                        alt="sign"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.kycDetails?.panUrl ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.kycDetails?.panUrl}
                      target="_blank">
                      <img
                        src={customer?.kycDetails?.panUrl}
                        alt="sign"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.kycDetails?.signatureUrl ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.kycDetails?.signatureUrl}
                      target="_blank">
                      <img
                        src={customer?.kycDetails?.signatureUrl}
                        alt="sign"
                        loading="lazy"
                        className="h-20 bg-white border-4 self-center"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.kycDetails?.invoiceUrl ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.kycDetails?.invoiceUrl}
                      target="_blank">
                      <img
                        src={customer?.kycDetails?.invoiceUrl}
                        alt="sign"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
            </tr>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-200 ">
              <TableHeader heading="Price" />
              <TableHeader heading="Down Payment" />
              <TableHeader heading="Remaining" />
              <TableHeader heading="Frequency" />
              <TableHeader heading="Frequency Type" />
              <TableHeader heading="Rate of Interest" />
              <TableHeader heading="EMI Amount" />
              <TableHeader heading="Total Amount" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr
              // key={i}
              className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={"₹ " + customer?.emiDetails?.productPrice} />

              <TableElement value={"₹ " + customer?.emiDetails?.downPayment} />
              <TableElement
                value={"₹ " + customer?.emiDetails?.remainingPayment}
              />
              <TableElement value={customer?.emiDetails?.frequency} />
              <TableElement value={customer?.emiDetails?.frequencyType} />
              <TableElement value={customer?.emiDetails?.rateOfIntrest} />
              <TableElement value={"₹ " + customer?.emiDetails?.emiAmount} />
              <TableElement
                value={"₹ " + customer?.emiDetails?.totalEMIAmount}
              />
            </tr>
          </tbody>
        </table>

        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-200 ">
              <TableHeader heading="EMI DATE" />
              <TableHeader heading="STATUS" />
              <TableHeader heading="AMOUNT" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {customer?.emiDetails?.emiDueDates?.map((item, i) => (
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100">
                <TableElement value={formatDate(item?.emiDate)} />
                <TableElement
                  value={
                    item?.status === "PAID" ? (
                      <p className="text-green-500">PAID</p>
                    ) : (
                      <p className="text-red-500">DUE</p>
                    )
                  }
                />

                <TableElement value={"₹ " + customer?.emiDetails?.emiAmount} />
              </tr>
            ))}
          </tbody>
        </table>
      </DialogContent>
      {/* <DialogActions> */}
      {/* <Button onClick={generateInvoice}>Generate</Button> */}
      {/* </DialogActions> */}
    </Dialog>
  );
};
