import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";

export const updateToken = (payload) => {
  return axios.post(`${EMI_BOOK}/notification/token`, null, {
    params: {
      token: payload.token,
    },
    headers: getHeaders(true),
  });
};

export const getNotifications = () => {
  return axios.get(`${EMI_BOOK}/notification`, {
    headers: getHeaders(true),
  });
};

export const createNotifications = (payload) => {
  return axios.post(`${EMI_BOOK}/notification/send`, payload, {
    headers: getHeaders(true),
  });
};
