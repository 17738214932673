import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";

export async function getAllUsers(payload) {
  return await axios.get(`${EMI_BOOK}/users`, {
    params: {
      page: payload.page,
      size: payload.size,
      state: payload.state,
    },
    headers: getHeaders(true),
  });
}
