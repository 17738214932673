import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { getAllOrdersByFilter } from "apis/orders";
import { getPromotions } from "apis/promotions";
import { deletePromotion } from "apis/promotions";

export default function Promotions() {
  const color = "light";
  const [promotions, setPromotions] = useState([]);
  const [payload, setPayload] = useState({
    size: 25,
    page: 0,
  });

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrders = () => {
    getPromotions(payload)
      .then((res) => {
        console.log("users", res.data.data);
        setPromotions(res.data.data);
        // setBrands(res.data.data);
        // setfilteredBrands(brands);
      })
      .catch((err) => {
        console.log("dekhhh", err);
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

  const handleDelete = (id) => {
    deletePromotion(id)
      .then((res) => {
        console.log("del", res.data.data);
        toast("Promotion deleted successfully", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        getOrders();
      })
      .catch((err) => {
        console.log("dekhhh", err);
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };
    

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S no." />
                <TableHeader heading="Promotions" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {promotions.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i+1} />
                    <TableElement
                      value={
                        <img
                          src={item?.url}
                          height="100px%"
                          className="h-20 bg-white border-4"
                        />
                      }
                    />

                    <TableElement
                      value={
                        <button
                          className="bg-red-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => handleDelete(item?.id)}
                        >
                          Delete
                        </button>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
