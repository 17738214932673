import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import { createOrderByMobile } from "apis/orders";
import { uploadFile } from "apis/master";

export default function CreateOrders() {
  const color = "light";
  const [imagePreview, setImagePreview] = useState(null);
  const [payload, setPayload] = useState({
    mobile: "",
    imageUrl: "",
    key: 0,
    schemeKey: 0,
    amount: 0,
    price: 0,
  });

  const handleMobileChange = (e) => {
    const { value } = e.target;
    const mob = Number(value);
    setPayload((prev) => ({
      ...prev,
      mobile: mob,
    }));
  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    const pri = Number(value);
    setPayload((prev) => ({
      ...prev,
      price: pri,
    }));
  };

  const handleKeyChange = (e) => {
    const { value } = e.target;
    const key = Number(value);
    setPayload((prev) => ({
      ...prev,
      key,
      amount: key * prev.price,
    }));
  };

  const handleSchemeChange = (e) => {
    const { value } = e.target;
    const scheme = Number(value);
    setPayload((prev) => ({
      ...prev,
      schemeKey: scheme,
    }));
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = Number(value);
    setPayload((prev) => ({
      ...prev,
      amount,
      key: Math.floor(amount / prev.price),
    }));
  };

  const handleCreateOrders = () => {
    createOrderByMobile(payload.mobile, payload)
      .then((res) => {
        // console.log("make", res.data.data);
        toast("Order created successfully", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setPayload({
          mobile: "",
          imageUrl: "",
          key: 0,
          schemeKey: 0,
          amount: 0,
          price: 175,
        });
        setImagePreview(null);
      })
      .catch((err) => {
        console.log("dekhhh", err);
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setPayload({
  //     ...payload,
  //     imageUrl: file,
  //   });
  //   setImagePreview(URL.createObjectURL(file));
  // };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setImagePreview(URL.createObjectURL(file));

    let body = new FormData();
    body.append("document", file);

    try {
      const { data } = await uploadFile(body, "PAYMENT");
      // console.log("ppppp", data.data.url);
      setPayload((prev) => ({ ...prev, imageUrl: data.data.url }));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        {/* ADD SERIES DIALOG */}
        <DialogTitle>Cretate Order</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Mobile"
                variant="outlined"
                fullWidth
                value={payload.mobile}
                onChange={handleMobileChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Price"
                variant="outlined"
                fullWidth
                value={"" + payload.price}
                onChange={handlePriceChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Keys Required"
                variant="outlined"
                fullWidth
                value={"" + payload.key}
                onChange={handleKeyChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Scheme"
                variant="outlined"
                fullWidth
                value={payload.scheme}
                onChange={handleSchemeChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Amount"
                variant="outlined"
                fullWidth
                value={"" + payload.amount}
                onChange={handleAmountChange}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                id="payment-screenshot"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <label htmlFor="payment-screenshot">
                <Button variant="contained" component="span">
                  Upload Payment Screenshot
                </Button>
              </label>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Payment Screenshot Preview"
                  style={{ marginTop: 10, maxHeight: 100 }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateOrders}
          >
            Create
          </Button>
        </DialogActions>
      </div>
    </>
  );
}
