import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { getAllOrdersByFilter } from "apis/orders";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getInvoiceData } from "apis/orders";

export default function AllOrders() {
  const color = "light";
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false);

  const [payload, setPayload] = useState({
    status: "COMPLETED",
    page: 0,
    size: 25,
  });

  const [invoice, setInvoice] = useState({
    id: null,
    invoiceNo: null,
  });

  console.log("invoice", invoice);

  const handleCloseGenerateInvoice = () => {
    setOpenGenerateInvoice(false);
  };

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const getOrders = () => {
    getAllOrdersByFilter(payload)
      .then((res) => {
        console.log("dd", res.data.data);
        setOrders(res.data.data);
        setTotalPages(Math.ceil(res.data.totalElements / payload.size));
      })
      .catch((err) => {
        console.log("dekhhh", err);
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

  const generateInvoice = () => {
    getInvoiceData(invoice.id, invoice)
      .then((res) => {
        toast("Invoice created successfully", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpenGenerateInvoice(false);
        // setOrders(res.data.data);
        // setTotalPages(Math.ceil(res.data.totalElements / payload.size));
      })
      .catch((err) => {
        console.log("dekhhh", err);
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

  const handleGenerateInvoice = (row) => {
    setOpenGenerateInvoice(true);
    setInvoice({ ...invoice, id: row });
  };

  const handlePageChange = (event, value) => {
    setPayload((prev) => ({
      ...prev,
      page: value - 1, // Pagination component is 1-based, API is 0-based
    }));
  };

  const handleStatusChange = (event) => {
    setPayload((prev) => ({
      ...prev,
      status: event.target.value,
    }));
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="flex justify-center my-4">
          <Stack spacing={2}>
            <Pagination
              className={"text-white"}
              count={totalPages}
              page={payload.page + 1}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </div>
        <div className="flex justify-end mx-2 my-4">
          <FormControl variant="outlined" className="w-60">
            <InputLabel>Status</InputLabel>
            <Select
              value={payload.status}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value="PAYMENT_REQUIRED">Payment required</MenuItem>
              <MenuItem value="PAYMENT_FAILED">Payment failed</MenuItem>
              <MenuItem value="COMPLETED">Completed</MenuItem>
              <MenuItem value="CANCELLED">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S no." />
                <TableHeader heading="Image" />
                <TableHeader heading="User Details" />
                <TableHeader heading="Reseller" />
                <TableHeader heading="Status" />
                <TableHeader heading="Amount" />
                <TableHeader heading="Price" />
                <TableHeader heading="Keys" />
                <TableHeader heading="Schemes Key" />
                <TableHeader heading="createdOn" />
                <TableHeader heading="Invoice" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {orders.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement
                      value={
                        <img
                          src={item?.imageUrl}
                          height="100px"
                          className="w-20 bg-white border-4"
                        />
                      }
                    />

                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.user?.name} <br />
                          {item?.user?.email} <br />
                          Mobile - {item?.user?.mobile} <br />
                          Transaction_ID - {item?.tnxId}
                        </span>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        item?.reseller !== null ? item?.reseller?.name : ""
                      }
                    />
                    <TableElement value={item?.status} />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.amount} <br />
                        </span>
                      }
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.price} <br />
                        </span>
                      }
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.key} <br />
                        </span>
                      }
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.schemeKey} <br />
                        </span>
                      }
                    />
                    <TableElement value={item?.createdOn} />

                    <TableElement
                      value={
                        item?.invoiceUrl ? (
                          <>
                            <a href={item.invoiceUrl} target="__blank">
                              <Button
                                variant="text"
                                aria-describedby="showInvoice-popover"
                              >
                                Show
                              </Button>
                            </a>
                          </>
                        ) : (
                          <Button
                            variant="text"
                            onClick={() => handleGenerateInvoice(item?.id)}
                            aria-describedby="url-popover"
                          >
                            Generate
                          </Button>
                        )
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={openGenerateInvoice} onClose={handleCloseGenerateInvoice}>
        <DialogTitle>Generate Invoice</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="invoiceNo"
            label="Enter Invoice Number"
            type="text"
            onChange={(e) => {
              setInvoice({ ...invoice, invoiceNo: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={generateInvoice}>Generate</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
