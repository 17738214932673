import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";
import { getData } from "../utils";

export function createOrder(payload) {
  return axios.post(`${EMI_BOOK}/orders`, payload, {
    headers: getHeaders(true),
  });
}

export function createOrderByMobile(mobile, payload) {
  return axios.post(`${EMI_BOOK}/orders/` + mobile, payload, {
    headers: getHeaders(true),
  });
}

export function updateOrder(id, payload) {
  return axios.put(`${EMI_BOOK}/orders/` + id, payload, {
    headers: getHeaders(true),
  });
}

export function getOrders() {
  const user = getData("user");
  return axios.get(`${EMI_BOOK}/orders/${user.id}`, {
    headers: getHeaders(true),
  });
}

export function getInvoiceData(id, payload) {
  return axios.get(`${EMI_BOOK}/orders/invoice/` + id, {
    params: {
      invoiceNo: payload.invoiceNo,
    },
    headers: getHeaders(true),
  });
}

export function getAllOrders() {
  return axios.get(
    `${EMI_BOOK}/orders/filter?status=PAYMENT_REQUIRED&status=PAYMENT_UPDATED`,
    {
      params: {
        page: 0,
        size: 50,
      },
      headers: getHeaders(true),
    }
  );
}

export function getAllOrdersByFilter(payload) {
  return axios.get(`${EMI_BOOK}/orders/filter`, {
    params: {
      status: payload.status,
      page: payload.page,
      size: payload.size,
    },
    headers: getHeaders(true),
  });
}

export function updateOrderStatus(id, status) {
  console.log(status);
  return axios.put(`${EMI_BOOK}/orders/status/${id}`, null, {
    params: { status: status },
    headers: getHeaders(true),
  });
}
